import { useEffect, useState } from 'react';
import { LoginMethodType } from 'types';
import { LOGIN_TYPE } from 'consts';
import { useGetAuthLoginMethods } from 'api/auth';
import { LoginPassword } from './LoginPassword';
import { LoginOTP } from './LoginOTP';
import { ChooseLoginMethod } from './ChooseLoginMethod';
import { useSuccessLogin } from './hooks';
import { LoginSSO } from './LoginSSO';

type Props = {
  loginMethod: LoginMethodType | undefined;
  loginMethods: {
    [key in keyof typeof LOGIN_TYPE]: boolean;
  };
  backButtonAction: (() => void) | undefined;
  switchLoginMethod: () => void;
  extractAndSetLoginMethods: (l?: LoginMethodType[]) => void;
  invitationEmail: string | undefined;
  isOnDocumentInvitationRoute: boolean;
  isOnBoardInvitationRoute: boolean;
};

export const Login = ({
  loginMethod,
  loginMethods,
  backButtonAction,
  switchLoginMethod,
  extractAndSetLoginMethods,
  invitationEmail,
  isOnDocumentInvitationRoute,
  isOnBoardInvitationRoute,
}: Props) => {
  const [email, setEmail] = useState(invitationEmail || '');

  const hasLoginMethodEmail = Boolean(loginMethods?.email_code);
  const hasLoginMethodPassword = Boolean(loginMethods?.password);

  const onSuccess = useSuccessLogin();

  const { isLoading, data, error, mutate } = useGetAuthLoginMethods();

  useEffect(() => {
    if (data && !isOnDocumentInvitationRoute) {
      extractAndSetLoginMethods(data.loginMethods);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (loginMethod) {
    switch (loginMethod) {
      case LOGIN_TYPE.password:
        return (
          <LoginPassword
            email={email}
            setEmail={setEmail}
            hasLoginMethodEmail={hasLoginMethodEmail}
            switchLoginMethod={switchLoginMethod}
            onSuccess={onSuccess}
            backButtonAction={backButtonAction}
          />
        );
      case LOGIN_TYPE.azure:
      case LOGIN_TYPE.google:
        return (
          <LoginSSO
            email={email}
            provider={loginMethod as 'google' | 'azure'}
          />
        );
      case LOGIN_TYPE.email_code:
        return (
          <LoginOTP
            email={email}
            hasLoginMethodPassword={hasLoginMethodPassword}
            switchLoginMethod={switchLoginMethod}
            onSuccess={onSuccess}
            backButtonAction={backButtonAction}
            isOnDocumentInvitationRoute={isOnDocumentInvitationRoute}
          />
        );
      default:
        return (
          <LoginPassword
            email={email}
            setEmail={setEmail}
            hasLoginMethodEmail={hasLoginMethodEmail}
            switchLoginMethod={switchLoginMethod}
            onSuccess={onSuccess}
            backButtonAction={backButtonAction}
          />
        );
    }
  }

  /* prevents UI from blinking */
  if (isOnDocumentInvitationRoute || isOnBoardInvitationRoute) {
    return null;
  }

  return (
    <ChooseLoginMethod
      email={email}
      isLoading={isLoading}
      error={error}
      setEmail={setEmail}
      mutate={mutate}
    />
  );
};
