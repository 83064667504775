import { typesMap } from '../constants';

const parseNumber = (value, pergaminType) => {
  if (value === null) {
    return null;
  }

  const type = typesMap[pergaminType].type;

  if (!['number', 'integer'].includes(type)) {
    return null;
  }

  const result = type === 'number' ? parseFloat(value) : parseInt(value);
  if (isNaN(result)) {
    return null;
  }

  return result;
};

export default parseNumber;
