export const API_QUERY_KEYS = {
  authConfig: 'authConfig',
  board: 'board',
  boardInvitation: 'boardInvitation',
  boardTemplates: 'boardTemplates',
  companiesList: 'companiesList',
  companyDataFromGus: 'companyDataFromGus',
  companyGroups: 'companyGroups',
  dataStream: 'dataStream',
  dataStreams: 'dataStreams',
  dataType: 'dataType',
  dataTypes: 'dataTypes',
  documentInvitation: 'documentInvitation',
  documents: 'documents',
  externalLetterheadFile: 'externalLetterheadFile',
  externalTransactionEntryDebug: 'externalTransactionEntryDebug',
  invitationAuth: 'invitationAuth',
  invitationAuthOtp: 'invitationAuthOtp',
  notifications: 'notifications',
  organisation: 'organisation',
  organisationCustomizationsDocument: 'organisationCustomizationsDocument',
  organisationCustomizationsEmail: 'organisationCustomizationsEmail',
  organisationCustomizationsEmailPreview:
    'organisationCustomizationsEmailPreview',
  organisationBoards: 'organisationBoards',
  organisationTemplates: 'organisationTemplates',
  organisationUsers: 'organisationUsers',
  organisations: 'organisations',
  otpCode: 'otpCode',
  postCompany: 'postCompany',
  registryCourts: 'registryCourts',
  sso: 'sso',
  ssoAuth: 'ssoAuth',
  taxOffices: 'taxOffices',
  technicalUser: 'technicalUser',
  technicalUsers: 'technicalUsers',
  template: 'template',
  transaction: 'transaction',
  transactionDataStream: 'transactionDataStream',
  transactionDataType: 'transactionDataType',
  transactionEntries: 'transactionEntries',
  transactionEntry: 'transactionEntry',
  transactionEntryDebug: 'transactionEntryDebug',
  transactions: 'transactions',
  user: 'user',
  userBoards: 'userBoards',
  userNotifications: 'userNotifications',
  webhooks: 'webhooks',
  webhooksLogs: 'webhooksLogs',
};
