import { CONTRACTOR_TYPES } from '../../../../../../CompanyModal/constants';

const contractorTypes = Object.keys(CONTRACTOR_TYPES);

const commonComponents = {
  schemas: {
    Address: {
      type: 'object',
      properties: {
        city: {
          type: 'string',
        },
        zipCode: {
          type: 'string',
          pattern: '^[0-9]{2}-[0-9]{3}$',
        },
        street: {
          type: 'string',
        },
      },
      required: ['city', 'zipCode', 'street'],
    },
    Name: {
      type: 'string',
      maxLength: 200,
    },
    Nip: {
      type: 'string',
      pattern: '^[0-9]{10}$',
      description: 'Tax number',
    },
    Contractor: {
      type: 'object',
      properties: {
        companyType: {
          type: 'string',
          enum: contractorTypes,
        },
        companyName: {
          type: 'string',
          maxLength: 200,
        },
        name: {
          $ref: '#/components/schemas/Name',
        },
        surname: {
          $ref: '#/components/schemas/Name',
        },
        pesel: {
          type: 'string',
          pattern: '^[0-9]{11}$',
          description: 'National identification number',
        },
        nip: {
          $ref: '#/components/schemas/Nip',
        },
        regon: {
          type: 'string',
          pattern: '^[0-9]{9}|[0-9]{14}$',
          description: 'Statistical Identification Number',
        },
        krs: {
          type: 'string',
          pattern: '^[0-9]{10}$',
          description: 'National Court Register number',
        },
        identityDoc: {
          type: 'string',
        },
        motherName: {
          type: 'string',
          maxLength: 200,
        },
        fatherName: {
          type: 'string',
          maxLength: 200,
        },
        registryCourt: {
          type: 'string',
        },
        address: {
          $ref: '#/components/schemas/Address',
        },
        contactAddress: {
          $ref: '#/components/schemas/Address',
        },
        differentContactAddress: {
          type: 'boolean',
        },
        email: {
          type: 'string',
          format: 'email',
        },
        phone: {
          type: 'string',
        },
        representatives: {
          type: 'array',
          items: {
            type: 'string',
          },
        },
        partners: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              name: {
                $ref: '#/components/schemas/Name',
              },
              surname: {
                $ref: '#/components/schemas/Name',
              },
              nip: {
                $ref: '#/components/schemas/Nip',
              },
              address: {
                $ref: '#/components/schemas/Address',
              },
            },
            required: ['name', 'surname', 'nip', 'address'],
          },
        },
        bankAccount: {
          type: 'string',
        },
        taxOffice: {
          type: 'string',
        },
        shareCapital: {
          type: 'string',
        },
        paidCapital: {
          type: 'string',
        },
        internalName: {
          type: 'string',
          maxLength: 200,
        },
        internalNumber: {
          type: 'string',
          maxLength: 200,
        },
        groups: {
          type: 'string',
        },
      },
      required: ['companyType', 'address'],
    },
  },
};

export default commonComponents;
