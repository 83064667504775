import { useTranslation } from 'react-i18next';
import { Menu, ActionIcon } from '@mantine/core';
import {
  EditIcon,
  TrashIcon,
  EraseIcon,
  ContextMenuIcon,
  CopyIcon,
} from 'icons';
import { useState } from 'react';

export const VariableContextMenu = ({
  position = 'bottom-end',
  withinPortal = false,

  ns = 'templates',
  eraserLabel = 'templates:templateCreatorVariables.clearVariable',
  editLabel = 'templates:templateCreatorVariables.editVariable',
  removeLabel = 'templates:templateCreatorVariables.removeVariable',
  copyLabel: copyLabel = 'templates:templateCreatorVariables.copyVariable',

  onOpen = () => null,
  onClose = () => null,

  onClearClick,
  onCopyClick: onCopyClick,
  onEditClick,
  onRemoveClick,
}) => {
  const { t } = useTranslation(ns);
  const [opened, setOpened] = useState(false);

  const change = open => {
    setOpened(open);
    if (open) {
      onOpen();
    } else {
      onClose();
    }
  };

  return (
    <Menu
      opened={opened}
      withinPortal={withinPortal}
      position={position}
      onChange={change}
    >
      <Menu.Target>
        <ActionIcon onClick={e => e.stopPropagation()}>
          <ContextMenuIcon />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        {onCopyClick && (
          <Menu.Item
            sx={{
              '.mantine-Menu-itemLabel': {
                display: 'flex',
                alignItems: 'center',
              },
            }}
            onClick={e => {
              e.stopPropagation();
              onCopyClick();
            }}
          >
            <CopyIcon
              sx={theme => ({
                marginRight: theme.other.spacing(1),
              })}
            />
            {t(copyLabel)}
          </Menu.Item>
        )}
        {onEditClick && (
          <Menu.Item
            sx={{
              '.mantine-Menu-itemLabel': {
                display: 'flex',
                alignItems: 'center',
              },
            }}
            onClick={e => {
              e.stopPropagation();
              onEditClick();
            }}
          >
            <EditIcon
              sx={theme => ({
                marginRight: theme.other.spacing(1),
              })}
            />
            {t(editLabel)}
          </Menu.Item>
        )}
        {onClearClick && (
          <Menu.Item
            sx={{
              '.mantine-Menu-itemLabel': {
                display: 'flex',
                alignItems: 'center',
              },
            }}
            onClick={e => {
              e.stopPropagation();
              onClearClick();
            }}
          >
            <EraseIcon
              sx={theme => ({
                marginRight: theme.other.spacing(1),
              })}
            />
            {t(eraserLabel)}
          </Menu.Item>
        )}
        {onRemoveClick && (
          <Menu.Item
            color="red"
            sx={{
              '.mantine-Menu-itemLabel': {
                display: 'flex',
                alignItems: 'center',
              },
            }}
            onClick={e => {
              e.stopPropagation();
              onRemoveClick();
            }}
          >
            <TrashIcon
              sx={theme => ({
                marginRight: theme.other.spacing(1),
              })}
            />
            {t(removeLabel)}
          </Menu.Item>
        )}
      </Menu.Dropdown>
    </Menu>
  );
};
