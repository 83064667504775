import { TablePosition } from '../utils';
import { insertRow } from '../changes';

/**
 * Insert a new row when pressing "Enter"
 */
export default function onEnter(event, editor, opts, next) {
  event.preventDefault();
  const { selection, document } = editor.value;
  const pos = TablePosition.create(opts, document, selection.start.key);

  if (
    !selection.focus.isAtStartOfNode(pos.cell) &&
    !selection.focus.isAtEndOfNode(pos.cell)
  ) {
    return next();
  }

  if (event.shiftKey) {
    const node = editor.value.document.getClosest(
      editor.value.endBlock.key,
      v => v.object === 'block' && v.type === opts.typeTable,
    );

    if (node) {
      editor.moveToEndOfNode(node);
      const path = editor.value.document
        .getPath(editor.value.endBlock.key)
        .toJSON();
      const index = path[path.length - 3];
      editor
        .splitNodeByKey(node.key, index + 1)
        .moveForward(1)
        .setBlocks('paragraph');

      return null;
    }

    return next();
  }

  if (event.altKey) {
    return editor.splitBlock().setBlocks({ type: opts.typeContent, data: {} });
  }

  return insertRow(opts, editor);
}
