import { apiAuthClient } from 'api/helpers';

import { GetAuthConfigResponseType } from '../types';

const getAuthConfig = async ({ ...params }) => {
  const url = `/api/v2/auth/user/auth-config`;

  /**
   * Endpoint can return 204 (no content) if SSO is not enabled
   * When SSO is enabled then return 200 with body
   */
  const { data } = await apiAuthClient.get<
    GetAuthConfigResponseType | undefined
  >(url, params);

  return (
    data?.data || {
      allowedDomains: [],
      provider: undefined,
      tenantId: undefined,
    }
  );
};

export default getAuthConfig;
