import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useTemplateStore } from 'stores';

import { Box, Select, Switch, Tabs, Text, Textarea } from '@mantine/core';
import {
  DatePickerSingleInput,
  NumberInput,
  TextInput,
} from 'components/shared';

import { LoopWrapperContext } from '../createRules';
import { MissingData } from './common/MissingData';

import { getVariableName, moneyToWords } from '../helpers';
import { getPredefinedVariables } from '../../TemplateCreatorEditor/utils';
import { ContractorPicker } from '../../TemplateCreatorEditor/VariableTypeFields/InitialCompaniesValue/ContractorPicker';

export const Input = ({ obj, template }) => {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();
  const { previewEditMode } = useTemplateStore();
  const { t } = useTranslation('templates');

  // expand template variables by cost variables like meta.*
  // this fallback when user has already variables like meta.* in data
  // @see https://pergamin.atlassian.net/browse/PERG-1728
  const expandedVariables = [
    ...template.document.data.variables,
    ...getPredefinedVariables(t),
  ];

  const variable = expandedVariables.find(
    v => v.name === obj.data.get('variable'),
  );
  if (variable === undefined) return null;
  const loopWrapperIndex = useContext(LoopWrapperContext);
  const variableName = getVariableName(variable, loopWrapperIndex);
  const value = watch(variableName);
  const error = errors[variableName]?.message;

  switch (variable.type) {
    case 'boolean':
      if (!previewEditMode) {
        return value || <MissingData />;
      }

      return (
        <Box display="inline-flex" direction="row">
          <Switch
            checked={value}
            onChange={() => setValue(variableName, !value)}
            style={{ marginRight: '0.5rem' }}
          />
          <Box mr="sm">
            <Text>{variable.label}</Text>
          </Box>
        </Box>
      );
    case 'company':
      if (!previewEditMode) {
        return null;
      }

      return (
        <ContractorPicker
          error={null}
          value={value}
          setValue={v => {
            setValue(variableName, v);
          }}
        />
      );
    case 'date':
      if (!previewEditMode) {
        return value ? value.toLocaleDateString('pl-PL') : <MissingData />;
      }

      return (
        <Box
          sx={theme => ({
            display: 'inline-block',
            verticalAlign: 'middle',
            flexDirection: 'column',
            paddingBottom:
              variable.label && !variable.hint ? theme.other.spacing(3.75) : 0,
          })}
        >
          <DatePickerSingleInput
            name={variableName}
            label={variable.label}
            placeholder={variable.placeholder}
            value={value}
            onChange={v => setValue(variableName, v)}
            size="xs"
            style={{
              display: 'inline-block',
              width: '200px',
            }}
            sx={theme => ({
              label: {
                textAlign: 'left',
                color: theme.colors.gray[4],
              },
            })}
            maxYearDelta={variable.maxYearDelta}
            minYearDelta={variable.minYearDelta}
          />
          {variable.hint && (
            <Text sx={{ marginTop: '2px', color: '#999' }} size="xs">
              {variable.hint}
            </Text>
          )}
        </Box>
      );
    case 'money':
      const moneyTranslation = obj.data.get('moneyTranslation');
      const langCode = moneyTranslation || variable.defaultMoneyTranslation;
      const moneyValueAppendix = variable.translateMoneyValue
        ? ` ${moneyToWords(value, variable.currency, langCode)}`
        : '';

      if (!previewEditMode)
        return `${value}${moneyValueAppendix}` || <MissingData />;
      else
        return (
          <Box
            sx={theme => ({
              display: 'inline-block',
              verticalAlign: 'middle',
              flexDirection: 'column',
              paddingBottom:
                variable.label && !variable.hint
                  ? theme.other.spacing(3.75)
                  : 0,
            })}
          >
            <NumberInput
              error={error}
              name={variableName}
              value={value}
              onChange={v => setValue(variableName, v)}
              min={0}
              step={1}
              precision={2}
              label={variable.label}
              placeholder={variable.placeholder}
              size="xs"
              sx={theme => ({
                display: 'inline-block',
                width: '200px',
                label: {
                  textAlign: 'left',
                  color: theme.colors.gray[4],
                },
              })}
              withErrorWrapperAlwaysEnabled={false}
            />
            {moneyValueAppendix}
            {variable.hint && (
              <Text sx={{ marginTop: '2px', color: '#999' }} size="xs">
                {variable.hint}
              </Text>
            )}
          </Box>
        );
    case 'textarea':
      if (!previewEditMode) {
        if (!value) return <MissingData />;
        const lines = value.split('\n');
        return lines.map((line, i) => (
          <>
            {line}
            {i < lines.length - 1 && <br />}
          </>
        ));
      }

      return (
        <Box
          sx={{
            display: 'inline-block',
            verticalAlign: 'middle',
            flexDirection: 'column',
          }}
        >
          <Textarea
            {...register(variableName)}
            name={variableName}
            label={variable.label}
            sx={theme => ({
              label: {
                textAlign: 'left',
                color: theme.colors.gray[4],
              },
              width: 400,
            })}
            placeholder={variable.placeholder}
            autosize
          />
          {variable.hint && (
            <Text sx={{ marginTop: '2px', color: '#999' }} size="xs">
              {variable.hint}
            </Text>
          )}
        </Box>
      );
    case 'dynamicList':
    case 'select':
      if (!previewEditMode) {
        return value || <MissingData />;
      }

      const options = variable.options.map(o => ({
        value: o.value,
        label: o.value,
      }));

      return (
        <Box
          sx={theme => ({
            display: 'inline-block',
            verticalAlign: 'middle',
            flexDirection: 'column',
            paddingBottom:
              variable.label && !variable.hint ? theme.other.spacing(3.75) : 0,
          })}
        >
          <Select
            name={variableName}
            data={options}
            size="xs"
            style={{ display: 'inline-block', width: '320px' }}
            error={null}
            value={value}
            onChange={v => {
              setValue(variableName, v);

              try {
                const fields = variable.listsToBeClearedOnChange
                  .replace(/\s/g, '')
                  .trim()
                  .split(',');

                for (const name of fields)
                  if (name !== field.name) setValue(name, null);
              } catch {}
            }}
          />
          {variable.hint && (
            <Text sx={{ marginTop: '2px', color: '#999' }} size="xs">
              {variable.hint}
            </Text>
          )}
        </Box>
      );
    case 'tabs':
      if (!previewEditMode) {
        return null;
      }

      const tabs = variable.options.map(o => o.value);

      return (
        <Tabs
          value={value}
          onTabChange={tabValue => setValue(variableName, tabValue)}
        >
          <Tabs.List sx={theme => ({ marginBottom: theme.other.spacing(2) })}>
            {tabs.map(tab => (
              <Tabs.Tab key={tab} value={tab}>
                {tab}
              </Tabs.Tab>
            ))}
          </Tabs.List>
        </Tabs>
      );
    case 'number':
    case 'integer':
      if (!previewEditMode) {
        return value || <MissingData />;
      }

      return (
        <Box
          sx={theme => ({
            display: 'inline-block',
            verticalAlign: 'middle',
            flexDirection: 'column',
            paddingBottom:
              variable.label && !variable.hint ? theme.other.spacing(3.75) : 0,
          })}
        >
          <NumberInput
            error={error}
            name={variableName}
            value={value}
            onChange={v =>
              setValue(variableName, v, {
                shouldValidate: true, // trigger validation
                shouldTouch: true, // update touched fields form state
                shouldDirty: true, // update dirty and dirty fields form state
              })
            }
            withErrorWrapperAlwaysEnabled={false}
            label={variable.label}
            placeholder={variable.placeholder}
            size="xs"
            sx={theme => ({
              display: 'inline-block',
              width: '200px',
              label: {
                textAlign: 'left',
                color: theme.colors.gray[4],
              },
            })}
            type="number"
          />
          {variable.hint && (
            <Text sx={{ marginTop: '2px', color: '#999' }} size="xs">
              {variable.hint}
            </Text>
          )}
        </Box>
      );
    case 'text':
      if (!previewEditMode) {
        return value || <MissingData />;
      }

      return (
        <Box
          sx={theme => ({
            display: 'inline-block',
            verticalAlign: 'middle',
            flexDirection: 'column',
            paddingBottom:
              variable.label && !variable.hint ? theme.other.spacing(3.75) : 0,
          })}
        >
          <TextInput
            {...register(variableName)}
            error={error}
            withErrorWrapperAlwaysEnabled={false}
            name={variableName}
            label={variable.label}
            placeholder={variable.placeholder}
            size="xs"
            sx={theme => ({
              display: 'inline-block',
              width: '200px',
              label: {
                fontSize: '12px',
                lineHeight: '12px',
                textAlign: 'left',
                color: theme.colors.gray[4],
                margin: 0,
              },
            })}
          />
          {variable.hint && (
            <Text sx={{ marginTop: '2px', color: '#999' }} size="xs">
              {variable.hint}
            </Text>
          )}
        </Box>
      );
    default:
      return null;
  }
};
