import { useTranslation } from 'react-i18next';

import { VARIABLES } from 'legacy/TemplateCreatorEditor/consts';

import { Box, List, Stack, Text, UnstyledButton } from '@mantine/core';
import { VariableMapping } from 'legacy/TemplateCreatorEditor/VariableMapping';
import { VariableIcon } from 'legacy/TemplateCreatorEditor/icons';
import { EditIcon } from 'icons';
import { useContext } from 'react';
import {
  LoopWrapperContext,
  LoopWrapperContextProvider,
} from '../../loopWrapper/context';
import { LoopWrapperToolBar } from '../../loopWrapper/LoopWrapperToolBar';

export const ListItem = ({ children, node, attributes, editor, isActive }) => {
  const { t } = useTranslation('templates');
  const loopWrapperContext = useContext(LoopWrapperContext);
  const variables = editor.getVariables();
  const variableName = node.data.get('variable');
  const variable = variables.find(({ name }) => name === variableName);
  const variableType = variable?.type;
  const variableIcon = variableType
    ? VARIABLES.find(({ value }) => value === variableType)?.icon
    : null;
  const loopIssues = variable ? variable.list && !loopWrapperContext : false;
  const isLoop = Boolean(node.data.get('loop'));

  const generateStyle = theme =>
    isLoop
      ? {
          margin: theme.other.spacing(1, 0),
          padding: theme.other.spacing(2),
          border: `1px solid ${
            isActive
              ? theme.focusRingStyles.inputStyles(theme).borderColor
              : theme.colors.gray[0]
          }`,
          borderRadius: theme.radius.sm,
          boxShadow: theme.shadows.xs,
        }
      : {
          paddingRight: theme.other.spacing(4),
        };

  const component = (
    <List.Item sx={generateStyle}>
      <Box sx={{ display: 'flex' }}>
        <Stack
          spacing={1}
          sx={theme => ({ marginBottom: theme.other.spacing(1) })}
        >
          <VariableMapping
            allowedTypes={['boolean']}
            defaultType="boolean"
            {...attributes}
            node={node}
            allowNullSelection
          >
            <Box
              sx={theme => ({
                position: 'relative',
                display: 'inline-block',
                marginLeft: theme.other.spacing(1),
                userSelect: 'none',
                verticalAlign: 'middle',
              })}
              onClick={e => e.stopPropagation()}
            >
              <UnstyledButton
                sx={theme => {
                  const activeBorderColor =
                    theme.focusRingStyles.inputStyles(theme).borderColor;
                  const getBorderColor = () =>
                    isActive ? activeBorderColor : theme.colors.gray[2];

                  return {
                    display: 'inline-flex',
                    alignItems: 'center',
                    gap: theme.other.spacing(1),
                    padding: theme.other.spacing(0.75, 2),
                    fontSize: theme.fontSizes.lg,
                    fontWeight: 500,
                    backgroundColor: variableName
                      ? theme.colors.gray[1]
                      : undefined,
                    border: `1px solid ${theme.colors.gray[2]}`,
                    borderColor: getBorderColor(),
                    borderRadius: theme.radius.sm,
                    whiteSpace: 'nowrap',
                  };
                }}
              >
                {variableIcon ? variableIcon : <VariableIcon color="blue" />}
                <Box sx={{ maxWidth: 240 }} component="span">
                  {variableName ? variableName : t('templates:chooseVariable')}
                </Box>
                {Boolean(variableName) && (
                  <EditIcon
                    sx={theme => ({
                      marginLeft: theme.other.spacing(1),
                    })}
                    color="primary"
                  />
                )}
              </UnstyledButton>
            </Box>
          </VariableMapping>
          {loopIssues && (
            <Text color="red">
              {t('templates:templateCreatorVariables.listIssues')}
            </Text>
          )}
        </Stack>
        {isLoop && (
          <Box
            sx={theme => ({
              marginLeft: theme.other.spacing(1),
              paddingTop: theme.other.spacing(1),
            })}
          >
            <LoopWrapperToolBar editor={editor} node={node} />
          </Box>
        )}
        <Box
          sx={theme => ({
            display: 'inline-block',
            marginLeft: theme.other.spacing(1),
            lineHeight: 3,
            minWidth: 1,
          })}
        >
          {children}
        </Box>
      </Box>
    </List.Item>
  );

  return isLoop ? (
    <LoopWrapperContextProvider>{component}</LoopWrapperContextProvider>
  ) : (
    component
  );
};
