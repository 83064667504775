import { Box } from '@mantine/core';

import { useGetColor } from 'hooks';

import { IconPropsType } from './types';

export const HintIcon = ({ color, size = 24, sx, ...props }: IconPropsType) => {
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="-3 -3 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.14286 9.00851H11.8571M5 5.57771H13M6.71429 13.5747L9 17.0033L11.2857 13.5747H15.8571C16.4891 13.5747 17 13.0639 17 12.4319V2.14615C17 1.51415 16.4891 1.0033 15.8571 1.0033H2.14286C1.51086 1.0033 1 1.51415 1 2.14615V12.4319C1 13.0639 1.51086 13.5747 2.14286 13.5747H6.71429Z"
        stroke={iconColor}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </Box>
  );
};
