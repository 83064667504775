import { commonComponents } from './constants';
import { getProperties, getRequired, hasCompanyType } from './utils';

const getSpecification = values => {
  const fields = values.filter(({ type }) => type !== 'conditional');
  const required = fields.length
    ? {
        required: getRequired(fields),
      }
    : null;
  const components = hasCompanyType(fields)
    ? {
        components: commonComponents,
      }
    : null;

  return {
    paths: {
      '/': {
        post: {
          requestBody: {
            description: 'Create a document',
            content: {
              'application/json': {
                schema: {
                  type: 'object',
                  properties: getProperties(fields),
                  ...required,
                },
              },
            },
          },
          responses: {
            201: {
              description: 'Created',
            },
          },
        },
      },
    },
    ...components,
  };
};

export default getSpecification;
