import { Box } from '@mantine/core';

import { useGetColor } from 'hooks';

import { IconPropsType } from './types';

export const PartiesIcon = ({
  color,
  size = 24,
  sx,
  ...props
}: IconPropsType) => {
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="-4 -3 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.91667 5.7533H3.25M6.58333 5.7533H5.91667M3.91667 3.7533H3.25M6.58333 3.7533H5.91667"
        stroke={iconColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5834 9.75334H10.9167M11.5834 7.08667H10.9167"
        stroke={iconColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.58333 5.08665V13.7533H13.9167V6.41998C13.9167 5.6836 13.3197 5.08665 12.5833 5.08665H8.58333ZM8.58333 5.08665V2.42C8.58333 1.68362 7.9864 1.08667 7.25 1.08667H2.58333C1.84695 1.08667 1.25 1.68362 1.25 2.42V6.41998"
        stroke={iconColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.91665 14.42V14.92C6.19278 14.92 6.41665 14.6961 6.41665 14.42H5.91665ZM0.583313 14.42H0.083313C0.083313 14.6961 0.307173 14.92 0.583313 14.92V14.42ZM4.08331 9.08663C4.08331 9.5469 3.71022 9.91996 3.24998 9.91996V10.92C4.2625 10.92 5.08331 10.0992 5.08331 9.08663H4.08331ZM3.24998 9.91996C2.78974 9.91996 2.41665 9.5469 2.41665 9.08663H1.41665C1.41665 10.0992 2.23745 10.92 3.24998 10.92V9.91996ZM2.41665 9.08663C2.41665 8.62636 2.78974 8.2533 3.24998 8.2533V7.2533C2.23745 7.2533 1.41665 8.0741 1.41665 9.08663H2.41665ZM3.24998 8.2533C3.71022 8.2533 4.08331 8.62636 4.08331 9.08663H5.08331C5.08331 8.0741 4.2625 7.2533 3.24998 7.2533V8.2533ZM5.91665 13.92H0.583313V14.92H5.91665V13.92ZM1.08331 14.42C1.08331 13.2234 2.05336 12.2533 3.24998 12.2533V11.2533C1.50107 11.2533 0.083313 12.671 0.083313 14.42H1.08331ZM3.24998 12.2533C4.4466 12.2533 5.41665 13.2234 5.41665 14.42H6.41665C6.41665 12.671 4.99889 11.2533 3.24998 11.2533V12.2533Z"
        fill={iconColor}
      />
    </Box>
  );
};
