import { useGetAuthConfig } from '../api/auth/hooks';

export type UseSsoEnabledOptions = {
  /**
   * Default value to return if the hook is unable to determine whether SSO is enabled or not
   * @default true
   */
  defaultValue?: boolean;
};

/**
 * Return information, whether SSO is enabled or not for the current user
 * @returns {boolean} - SSO enabled or not
 * @example const enabled = useGetAuthConfig();
 */
export const useSsoEnabled = (options?: UseSsoEnabledOptions) => {
  const { data } = useGetAuthConfig();

  // whe not have data, return default value
  if (data === undefined) {
    return options?.defaultValue ?? true;
  }

  return data?.provider !== undefined;
};
