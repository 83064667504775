import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Paper, Popover, Text, Loader } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';

import { ChevronDownIcon, CreateIcon } from 'icons';
import { DEBOUNCED_INPUT_DELAY_IN_MS } from 'consts';
import { useGetCurrentBoardId } from 'hooks';
import { useGetCompanies } from 'api/company/';

import { CompanyModal } from '../../CompanyModal';
import { NotFound, TextInputSearch, TextInput } from 'components/shared';

export const ContractorPicker = ({
  value,
  setValue,
  error,
  canAddNewContractor,
  companiesPerPage = 25,
}) => {
  const { t } = useTranslation('templates');
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [addNewCompanyVisible, setAddNewCompanyVisible] = useState(false);

  const currentBoardId = useGetCurrentBoardId();
  const [debouncedSearchValue] = useDebouncedValue(
    searchValue,
    DEBOUNCED_INPUT_DELAY_IN_MS,
  );

  const { data: companies = [], isInitialLoading: isLoadingCompanies } =
    useGetCompanies(
      {
        pathParams: { boardId: currentBoardId },
        queryParams: {
          perPage: companiesPerPage,
          search: debouncedSearchValue,
        },
      },
      { enabled: Boolean(currentBoardId) },
    );

  const selectedCompanyName = value
    ? value.companyName || `${value.name} ${value.surname}`
    : null;

  const showCompanies = companies.length > 0;
  const showNotFound = companies.length === 0 && !isLoadingCompanies;

  return (
    <>
      {addNewCompanyVisible && (
        <CompanyModal
          handleClose={() => {
            setAddNewCompanyVisible(false);
          }}
        />
      )}
      <Popover
        opened={isPopoverOpen}
        exitTransitionDuration={0}
        withinPortal
        onChange={open => {
          setIsPopoverOpen(open);

          if (!open) {
            setSearchValue('');
          }
        }}
      >
        <Popover.Target
          onClick={e => {
            e.stopPropagation();
            setIsPopoverOpen(open => !open);
          }}
        >
          <TextInput
            rightIcon={<ChevronDownIcon />}
            placeholder={t(
              'templates:templateCreatorVariables.contractorPlaceholder',
            )}
            value={selectedCompanyName}
            error={error}
            sx={theme => {
              const getBorderColor = () => {
                return error ? theme.colors.red[0] : undefined;
              };

              return {
                input: {
                  backgroundColor: error ? theme.colors.red[1] : undefined,
                  borderColor: getBorderColor(),
                },
              };
            }}
            forceFocusSate={isPopoverOpen}
            onClear={() => {
              setValue(null);
            }}
            readOnly
          />
        </Popover.Target>
        <Popover.Dropdown sx={{ padding: 0 }}>
          <Paper
            onClick={e => {
              e.stopPropagation();
            }}
            sx={{ width: '326px', height: '250px' }}
          >
            <TextInputSearch
              sx={theme => ({
                marginBottom: theme.other.spacing(1.25),
              })}
              autoFocus
              value={searchValue}
              onChange={e => {
                setSearchValue(e.target.value);
              }}
            />
            {canAddNewContractor && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  flexDirection: 'row',
                  alignItems: 'center',
                  alignContent: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setIsPopoverOpen(open => !open);
                  setAddNewCompanyVisible(true);
                }}
              >
                <CreateIcon
                  sx={theme => ({
                    marginRight: theme.other.spacing(1),
                    color: theme.colors.green[0],
                  })}
                />
                <Text
                  sx={theme => ({
                    color: theme.colors.green[0],
                  })}
                  size="lg"
                  weight={600}
                >
                  Dodaj nowego kontrahenta
                </Text>
              </Box>
            )}
            <Box
              sx={theme => ({
                marginTop: theme.other.spacing(2),
                marginBottom: theme.other.spacing(2),
              })}
            >
              {showCompanies && (
                <Box
                  sx={theme => ({
                    padding: theme.other.spacing(0, 1),
                    overflow: 'auto',
                    maxHeight: '130px',
                  })}
                  component="ul"
                >
                  {companies.map((company, index) => {
                    const { id, companyName, nip, name, surname } = company;

                    const isSelected = value ? value.id === id : false;
                    const key = `${companyName || name}+${index}`;
                    const companyLabel = companyName || `${name} ${surname}`;

                    return (
                      <Box
                        sx={theme => ({
                          display: 'flex',
                          flexDirection: 'column',
                          padding: '5px 8px',
                          marginBottom: theme.other.spacing(1),
                          justifyContent: 'center',
                          textAlign: 'left',
                          cursor: 'pointer',
                          backgroundColor: isSelected ? '#F7F8FF' : undefined,
                          color: isSelected ? '#685EF4' : undefined,
                          '&:hover': {
                            backgroundColor: '#F7F8FF',
                            color: '#685EF4',
                          },
                        })}
                        component="li"
                        key={key}
                        onClick={() => {
                          setValue(company);
                          setIsPopoverOpen(open => !open);
                        }}
                      >
                        <Text size="14px" weight={500}>
                          {companyLabel}
                        </Text>
                        <Text
                          size="14px"
                          weight={300}
                          sx={{
                            color: '#1E1C49',
                            '&:hover': {
                              color: '#685EF4',
                            },
                          }}
                        >
                          {nip}
                        </Text>
                      </Box>
                    );
                  })}
                </Box>
              )}
              {showNotFound && (
                <NotFound
                  sx={theme => ({
                    marginBottom: theme.other.spacing(2),
                  })}
                />
              )}
              {isLoadingCompanies && (
                <Loader
                  sx={{ position: 'absolute', inset: 0, margin: 'auto' }}
                />
              )}
            </Box>
          </Paper>
        </Popover.Dropdown>
      </Popover>
    </>
  );
};
