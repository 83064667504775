import { Box } from '@mantine/core';

import { useGetColor } from 'hooks';

import { IconPropsType } from './types';

export const ConditionalWrapperIcon = ({
  color,
  size = 24,
  sx,
  ...props
}: IconPropsType) => {
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="-3 -4 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 15.6218V0.384644H3.40232V1.67069H1.56279V14.3358H3.40232V15.6218H0Z"
        fill={iconColor}
      />
      <path
        d="M5.67204 9.11841C5.36816 9.11841 5.1077 9.01531 4.89064 8.80911C4.67359 8.59206 4.56506 8.32074 4.56506 7.99516C4.56506 7.66958 4.67359 7.40369 4.89064 7.19749C5.1077 6.99129 5.36816 6.88818 5.67204 6.88818C5.96506 6.88818 6.21467 6.99129 6.42088 7.19749C6.63793 7.40369 6.74646 7.66958 6.74646 7.99516C6.74646 8.32074 6.63793 8.59206 6.42088 8.80911C6.21467 9.01531 5.96506 9.11841 5.67204 9.11841Z"
        fill={iconColor}
      />
      <path
        d="M9.01628 9.11841C8.7124 9.11841 8.45194 9.01531 8.23488 8.80911C8.01783 8.59206 7.9093 8.32074 7.9093 7.99516C7.9093 7.66958 8.01783 7.40369 8.23488 7.19749C8.45194 6.99129 8.7124 6.88818 9.01628 6.88818C9.3093 6.88818 9.55891 6.99129 9.76511 7.19749C9.98217 7.40369 10.0907 7.66958 10.0907 7.99516C10.0907 8.32074 9.98217 8.59206 9.76511 8.80911C9.55891 9.01531 9.3093 9.11841 9.01628 9.11841Z"
        fill={iconColor}
      />
      <path
        d="M12.3604 9.11841C12.0565 9.11841 11.7961 9.01531 11.579 8.80911C11.3619 8.59206 11.2534 8.32074 11.2534 7.99516C11.2534 7.66958 11.3619 7.40369 11.579 7.19749C11.7961 6.99129 12.0565 6.88818 12.3604 6.88818C12.6534 6.88818 12.903 6.99129 13.1092 7.19749C13.3263 7.40369 13.4348 7.66958 13.4348 7.99516C13.4348 8.32074 13.3263 8.59206 13.1092 8.80911C12.903 9.01531 12.6534 9.11841 12.3604 9.11841Z"
        fill={iconColor}
      />
      <path
        d="M18 15.6218H14.5977V14.3358H16.4372V1.67069H14.5977V0.384644H18V15.6218Z"
        fill={iconColor}
      />
    </Box>
  );
};
