import { useFormContext } from 'react-hook-form';
import { Box, Switch } from '@mantine/core';

import { useTemplateStore } from 'stores';

export const Header = ({ obj, template, children }) => {
  const { previewEditMode } = useTemplateStore();
  const { setValue, watch } = useFormContext();

  const { variables } = template.document.data;
  const assignedVariableName = obj.data.get('variable');
  const assignedVariable =
    assignedVariableName &&
    variables.find(v => v.name === assignedVariableName);

  const shouldBeDisplayed = assignedVariable
    ? watch(assignedVariable.name)
    : true;
  const shouldBeStruckThrough = previewEditMode && !shouldBeDisplayed;

  if (!previewEditMode && !shouldBeDisplayed) return null;

  return (
    <Box
      component="h2"
      id={obj.data.get('id') ? `paragraph-${obj.data.get('id')}` : undefined}
      sx={{
        textAlign: 'center',
        color: shouldBeStruckThrough ? 'grey' : undefined,
        textDecoration: shouldBeStruckThrough ? 'line-through' : undefined,
        counterIncrement: shouldBeDisplayed ? 'legal-paragraph' : undefined,
      }}
    >
      {assignedVariable && previewEditMode && (
        <Switch
          checked={shouldBeDisplayed}
          onChange={() => setValue(assignedVariable.name, !shouldBeDisplayed)}
          sx={theme => ({
            display: 'inline-block',
            marginRight: theme.other.spacing(1),
            position: 'relative',
            top: theme.other.spacing(0.25),
          })}
        />
      )}
      <Box
        component="span"
        sx={{
          '&:before': {
            content: !shouldBeDisplayed
              ? "'§'"
              : "'§ ' counter(legal-paragraph)",
          },
        }}
      >
        <br />
        {children}
      </Box>
    </Box>
  );
};
