import { STORAGE_KEYS } from 'consts';

export const setToken = (token: string) => {
  window.localStorage.setItem(STORAGE_KEYS.token, token);
  window.dispatchEvent(
    new CustomEvent('mantine-local-storage', {
      detail: { key: STORAGE_KEYS.token, value: token },
    }),
  );
};
