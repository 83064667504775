import { Box } from '@mantine/core';

import { useGetColor } from 'hooks';

import { IconPropsType } from './types';

export const PageBreakIcon = ({
  color,
  size = 24,
  sx,
  ...props
}: IconPropsType) => {
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="-4 -4 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 9.24677H0.25V10.7468H1V9.24677ZM3 10.7468H3.75V9.24677H3V10.7468ZM5 9.24677H4.25V10.7468H5V9.24677ZM7 10.7468H7.75V9.24677H7V10.7468ZM9 9.24677H8.25V10.7468H9V9.24677ZM11 10.7468H11.75V9.24677H11V10.7468ZM13 9.24677H12.25V10.7468H13V9.24677ZM15 10.7468H15.75V9.24677H15V10.7468ZM11 1.0033L11.5303 0.472966L11.3107 0.253296H11V1.0033ZM14 4.0033H14.75V3.69264L14.5303 3.47297L14 4.0033ZM1 10.7468H3V9.24677H1V10.7468ZM5 10.7468H7V9.24677H5V10.7468ZM9 10.7468H11V9.24677H9V10.7468ZM13 10.7468H15V9.24677H13V10.7468ZM13 14.2533H3V15.7533H13V14.2533ZM3 1.7533H11V0.253296H3V1.7533ZM10.4697 1.53363L13.4697 4.53363L14.5303 3.47297L11.5303 0.472966L10.4697 1.53363ZM13.25 4.0033V8.5033H14.75V4.0033H13.25ZM13.25 12.2533V14.0033H14.75V12.2533H13.25ZM2.75 8.5033V2.0033H1.25V8.5033H2.75ZM2.75 14.0033V11.5033H1.25V14.0033H2.75ZM3 14.2533C2.86193 14.2533 2.75 14.1414 2.75 14.0033H1.25C1.25 14.9698 2.0335 15.7533 3 15.7533V14.2533ZM13 15.7533C13.9665 15.7533 14.75 14.9698 14.75 14.0033H13.25C13.25 14.1414 13.1381 14.2533 13 14.2533V15.7533ZM3 0.253296C2.0335 0.253296 1.25 1.0368 1.25 2.0033H2.75C2.75 1.86522 2.86193 1.7533 3 1.7533V0.253296Z"
        fill={iconColor}
      />
    </Box>
  );
};
