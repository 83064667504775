import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthContainerLoginHeader } from '../../AuthContainerLoginHeader';
import { SsoLoginMethods } from '../SsoLoginMethods';
import { LoginOptionsDivider } from '../LoginOptionsDivider';
import { useGetSso } from '../../../../api/singleSignOn';
import { HTTP_HEADERS, SSO_LOGIN_METHODS } from '../../../../consts';
import { RedirectToSsoModal } from './RedirectToSsoModal';
import { TextInput } from '../../../shared';

type Props = {
  email: string;
  provider: keyof typeof SSO_LOGIN_METHODS;
  backButtonAction?: () => void;
};

export const LoginSSO = ({ email, provider, backButtonAction }: Props) => {
  const { t } = useTranslation(['common', 'user']);
  const providerName = provider === 'google' ? 'Google' : 'Microsoft';

  if (Object.values(SSO_LOGIN_METHODS).indexOf(provider) === -1) {
    throw new Error(
      `Invalid provider, expected: ${Object.keys(SSO_LOGIN_METHODS).join(
        ', ',
      )}, got: ${provider}`,
    );
  }

  const { refetch: login } = useGetSso(
    {
      headers: {
        [HTTP_HEADERS.xRedirectUrl]: window.location.href,
      },
      pathParams: {
        ssoDriver: provider,
      },
    },
    {
      enabled: false,
      onSuccess: redirectTo => {
        window.location.assign(redirectTo);
      },
    },
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      login();
    }, 5000);

    return () => clearTimeout(timeout);
  }, [login]);

  return (
    <>
      <RedirectToSsoModal
        isOpen
        onClose={() => {}}
        providerName={providerName}
        onRedirectButtonClick={login}
      />
      <AuthContainerLoginHeader backButtonAction={backButtonAction} />
      <TextInput
        id="email"
        label={t('common:email')}
        placeholder={t('common:emailPlaceholder')}
        autoFocus
        value={email}
      />
      <LoginOptionsDivider />
      <SsoLoginMethods />
    </>
  );
};
