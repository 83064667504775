import { Record } from 'immutable';

/**
 * The plugin options
 */
class Options extends Record({
  types: ['orderedList'],
  typeItem: 'listItem',
  typeDefault: 'paragraph',
  canMerge: (a, b) => a.type === b.type,
}) {
  // The possibles types for list containers
  // types;
  // // The type of list items
  // typeItem;
  // // The type of default block in items
  // typeDefault;
  // // You can control here the automatic merging of adjacent lists
  // canMerge;
}

export default Options;
