import parseNumber from './parseNumber';

const getRange = (value, pergaminType, label) => {
  const number = parseNumber(value, pergaminType);
  if (number === null) {
    return null;
  }
  return {
    [label]: number,
  };
};

export default getRange;
