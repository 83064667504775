import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { usePrevious } from '@mantine/hooks';

import { useGetAuth } from 'api/invitationOtp';
import { getLoginMethodsObject } from 'helpers';
import { LOGIN_TYPE } from 'consts';
import { LoginMethodType } from 'types';
import { NonLoggedLayout } from 'components/NonLoggedLayout';

import { useGetBoardInvitation } from 'api/boards/hooks';
import { PromotionalCarousel } from '../PromotionalCarousel';
import { DeletedAccountModal } from './Login/DeletedAccountModal';
import { Login } from './Login';
import { Error } from '../shared';
import { useGetAuthLoginMethods } from '../../api/auth';
import { authMethodPriority } from '../../consts/authMethodPriority';
import { LOGIN_DEFAULT_TYPE } from '../../consts/loginType';

export const Auth = () => {
  const location = useLocation();

  const { invitationId } = useParams();
  const previousInvitationId = usePrevious(invitationId);
  const isOnDocumentInvitationRoute = Boolean(invitationId);

  const navigate = useNavigate();

  const [loginMethod, setLoginMethod] = useState<LoginMethodType>();
  const [loginMethods, setLoginMethods] = useState<{
    [key in keyof typeof LOGIN_TYPE]: boolean;
  }>({});

  const isAccountDeleted = Boolean(location.state?.isAccountDeleted);
  const [isDeletedAccountModalOpen, setIsDeletedAccountModalOpen] =
    useState(isAccountDeleted);

  const searchParams = new URLSearchParams(location.search);
  const organisationInvitationToken = searchParams.get(
    'organisation_invitation_token',
  );
  const isOnBoardInvitationRoute = Boolean(organisationInvitationToken);

  const {
    data: { email: invitationEmail } = {},
    isError: isInvitationLoginMethodsError,
    isInitialLoading: isInvitationLoginMethodsInitialLoading,
  } = useGetAuth(
    {
      pathParams: {
        invitationId: invitationId as string,
      },
    },
    {
      enabled: isOnDocumentInvitationRoute,
    },
  );

  const {
    data: { email: organisationInvitationEmail } = {},
    isInitialLoading: isGetBoardInvitationInitialLoading,
  } = useGetBoardInvitation(
    {
      pathParams: { invitationId: organisationInvitationToken || '' },
    },
    {
      enabled: Boolean(organisationInvitationToken),
    },
  );

  const {
    data: { loginMethods: authLoginMethods } = {},
    mutate: getAuthLoginMethods,
  } = useGetAuthLoginMethods();

  useEffect(() => {
    if (invitationEmail || organisationInvitationEmail) {
      getAuthLoginMethods({
        email: invitationEmail || organisationInvitationEmail!,
      });
    }
  }, [invitationEmail, getAuthLoginMethods, organisationInvitationEmail]);

  const resetLoginMethods = () => {
    setLoginMethod(undefined);
    setLoginMethods({});
  };

  const hasNavigatedFromInvitationToHome =
    invitationId === undefined && previousInvitationId;

  const extractAndSetLoginMethods = (loginMethodsArray?: LoginMethodType[]) => {
    const methods = getLoginMethodsObject(loginMethodsArray);
    const method = authMethodPriority.find(methodName => methods[methodName]);

    setLoginMethods(methods);
    setLoginMethod((method as LoginMethodType) || LOGIN_DEFAULT_TYPE);
  };

  useEffect(() => {
    if (
      authLoginMethods &&
      (isOnDocumentInvitationRoute || isOnBoardInvitationRoute)
    ) {
      extractAndSetLoginMethods(authLoginMethods);
    }
  }, [authLoginMethods, isOnBoardInvitationRoute, isOnDocumentInvitationRoute]);

  useEffect(() => {
    if (hasNavigatedFromInvitationToHome) {
      resetLoginMethods();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNavigatedFromInvitationToHome]);

  useEffect(() => {
    if (isAccountDeleted) {
      navigate('/', { replace: true }); // clears location.state
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAccountDeleted]);

  const switchLoginMethod = () => {
    setLoginMethod(previousLoginMethod =>
      previousLoginMethod === LOGIN_TYPE.email_code
        ? LOGIN_TYPE.password
        : LOGIN_TYPE.email_code,
    );
  };

  const isLoginViewLoading = () => {
    if (isOnDocumentInvitationRoute || isOnBoardInvitationRoute) {
      return (
        isInvitationLoginMethodsInitialLoading ||
        isGetBoardInvitationInitialLoading ||
        !loginMethod
      );
    }

    return false;
  };

  const backButtonAction =
    !isOnDocumentInvitationRoute &&
    !isOnBoardInvitationRoute &&
    loginMethod &&
    !hasNavigatedFromInvitationToHome
      ? resetLoginMethods
      : undefined;

  const getLoginViewContent = () => {
    if (isInvitationLoginMethodsError) {
      return <Error />;
    }

    return (
      <Login
        loginMethod={loginMethod}
        loginMethods={loginMethods}
        backButtonAction={loginMethod && resetLoginMethods}
        switchLoginMethod={switchLoginMethod}
        extractAndSetLoginMethods={extractAndSetLoginMethods}
        invitationEmail={invitationEmail || organisationInvitationEmail}
        isOnDocumentInvitationRoute={isOnDocumentInvitationRoute}
        isOnBoardInvitationRoute={isOnBoardInvitationRoute}
      />
    );
  };

  return (
    <>
      <NonLoggedLayout
        primaryContent={<PromotionalCarousel />}
        secondaryContent={getLoginViewContent()}
        isSecondaryContentLoading={isLoginViewLoading()}
        backButtonAction={backButtonAction}
      />
      <DeletedAccountModal
        isOpen={isDeletedAccountModalOpen}
        onClose={() => {
          setIsDeletedAccountModalOpen(false);
        }}
      />
    </>
  );
};
