import { UnstyledButton, Flex, Text, Loader, Stack } from '@mantine/core';
import { Modal } from 'components/shared';
import { useTranslation } from 'react-i18next';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onRedirectButtonClick: () => void;
  providerName: string;
};

export const RedirectToSsoModal = ({
  isOpen,
  onClose,
  providerName,
  onRedirectButtonClick,
}: Props) => {
  const { t } = useTranslation(['common', 'user']);

  return (
    <Modal
      isOpen={isOpen}
      size={442}
      onClose={onClose}
      withCloseButton={false}
      title={t('user:loginMethod.redirectToSSO.title')}
      actionButtons={
        <Flex justify="center" align="center" w="100%">
          <UnstyledButton
            onClick={() => onRedirectButtonClick()}
            sx={{
              padding: '6px 16px',
              textTransform: 'none',
              height: '30px',
              borderRadius: '4px',
              border: '1px solid #E6E6E6',
            }}
          >
            {t('user:loginMethod.redirectToSSO.cta')}
          </UnstyledButton>
        </Flex>
      }
    >
      <Stack mt={16}>
        <Flex justify="center">
          <Loader />
        </Flex>
        <Text size={14} align="center">
          {t('user:loginMethod.redirectToSSO.description', { providerName })}
        </Text>
      </Stack>
    </Modal>
  );
};
