import { LoginMethodType } from 'types';

export const LOGIN_TYPE: { [key: string]: LoginMethodType } = {
  email_code: 'email_code',
  password: 'password',
  sms_code: 'sms_code',
  azure: 'azure',
  google: 'google',
};

export const LOGIN_DEFAULT_TYPE = LOGIN_TYPE.password;
