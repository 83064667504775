import { typesMap } from '../constants';

const getType = (value, list) => {
  const type = typesMap[value];
  return type && list
    ? {
        type: 'array',
        items: { ...type },
      }
    : type;
};

export default getType;
