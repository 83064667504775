import { TemplateType } from '../../../types';
import { TemplateNode } from '../../../types/templates/ContractTemplateType';

const CREATOR_1_6 = '1.6';
const CREATOR_2_0 = '2.0';

/**
 * Template migrator
 * @param template
 */
export const templateMigrator = (template: TemplateType) => {
  const version = getCreatorVersion(template);

  if (version === CREATOR_1_6) {
    return from16To20(template);
  }

  return template;
};

/**
 * Get creator version from given template
 *
 * @param template
 * @return string - creator version
 */
const getCreatorVersion = (template: TemplateType) =>
  template.contractTemplate.document.data.creator || CREATOR_1_6;

/**
 * Template migrator from version 1.6 to 2.0
 *
 * On this migration:
 * - Apply language template from 1.6 to amount variables in 2.0
 *
 * @param data
 */
const from16To20 = (data: TemplateType) => ({
  ...data,
  contractTemplate: {
    ...data?.contractTemplate,
    document: {
      ...data?.contractTemplate?.document,
      data: {
        ...data?.contractTemplate?.document.data,
        variables: data?.contractTemplate?.document?.data?.variables?.map(
          (variable: any) => {
            if (variable.type === 'money') {
              return {
                ...variable,
                defaultMoneyTranslation:
                  data?.contractTemplate?.document?.data.lang || 'pl',
                translateMoneyValue: true,
              };
            }

            return variable;
          },
        ),
        creator: CREATOR_2_0,
      },
      nodes: data?.contractTemplate?.document?.nodes?.map(
        fromNodes16to20({
          variables: data?.contractTemplate?.document?.data?.variables,
        }),
      ),
    },
  },
});

/**
 * Object stores context for nodes migration
 */
type NodesContext = {
  variables: any[];
  languageWrapper?: 'pl' | 'en' | 'de' | string;
};

const fromNodes16to20 =
  (context: NodesContext = { variables: [] }) =>
  (node: TemplateNode): TemplateNode => {
    const result = { ...node };
    const newContext = { ...context };

    // update context
    if (result.type === 'languageWrapper') {
      newContext.languageWrapper = result.data?.lang;
    }

    // variables in document
    if (result.data?.variable && context.variables) {
      const variableName = result.data.variable;
      const variableSetup = context.variables.find(
        variable => variable.name === variableName,
      );

      if (variableSetup) {
        // when variable is type money, then update translation
        // this is simulating language blocs from 1.6
        if (variableSetup.type === 'money' && newContext.languageWrapper) {
          result.data = {
            ...result.data,
            moneyTranslation:
              result.data?.moneyTranslation || context.languageWrapper,
          };
        }
      }
    }

    if (result.nodes) {
      result.nodes = result.nodes.map(fromNodes16to20(newContext));
    }

    return result;
  };
