import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Menu } from '@mantine/core';

import {
  ConditionalWrapperIcon,
  ContextMenuIcon,
  HintIcon,
  PageBreakIcon,
  PartiesIcon,
  SignIcon,
} from 'icons';
import { EditorContext } from 'legacy/TemplateCreatorEditor/context';
import {
  VariableIcon,
  VariableValueIcon,
} from 'legacy/TemplateCreatorEditor/icons';

import { MenuItem } from '../../MenuItem';
import { EditorActionButton } from '../EditorActionButton';

export const InsertSection = () => {
  const { t } = useTranslation('templates');

  const editor = useContext(EditorContext);

  const ITEMS = [
    {
      value: 'field',
      translation: t('templates:field'),
      iconComponent: <VariableIcon />,
      action: () => {
        editor.insertInlineWithEmptyContent('input');
      },
    },
    {
      value: 'valueOfVariable',
      translation: t('templates:valueOfVariable'),
      iconComponent: (
        <Box
          sx={{
            width: 24,
            height: 24,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <VariableValueIcon />
        </Box>
      ),
      action: () => {
        editor.insertInlineWithEmptyContent('variable');
      },
    },
    {
      value: 'blockCondition',
      translation: t('templates:blockCondition'),
      iconComponent: <ConditionalWrapperIcon />,
      action: () => {
        editor.wrapBlockInConditionalWrapper();
      },
    },
    {
      value: 'linearCondition',
      translation: t('templates:linearCondition'),
      iconComponent: <ConditionalWrapperIcon />,
      action: () => {
        editor.wrapInlineInConditionalWrapper();
      },
    },
    {
      value: 'pageBreak',
      translation: t('templates:pageBreak'),
      iconComponent: <PageBreakIcon />,
      action: () => {
        editor.insertBlockWithOptionalEmptyContent('pageBreak');
      },
    },
    {
      value: 'partiesData',
      translation: t('templates:partiesData'),
      iconComponent: <PartiesIcon />,
      action: () => {
        editor.insertCompanyWrapper();
      },
    },
    {
      value: 'signatures',
      translation: t('templates:partiesSignatures'),
      iconComponent: <SignIcon />,
      action: () => {
        editor.insertBlockWithOptionalEmptyContent('signatures');
      },
    },
    {
      value: 'hint',
      translation: t('templates:hint'),
      iconComponent: <HintIcon />,
      action: () => {
        editor.insertInlineWithEmptyContent('tooltip');
      },
    },
  ];

  return (
    <Menu>
      <Menu.Target>
        <EditorActionButton>
          <ContextMenuIcon />
          {t('templates:toolbar.insert')}
        </EditorActionButton>
      </Menu.Target>
      <Menu.Dropdown>
        {ITEMS.map(({ value, translation, action, iconComponent }) => (
          <MenuItem
            key={value}
            onClick={action}
            icon={iconComponent}
            sx={theme => ({
              paddingLeft: theme.other.spacing(1.75),
              paddingTop: theme.other.spacing(0.75),
              paddingRight: theme.other.spacing(2),
              paddingBottom: theme.other.spacing(0.75),
            })}
          >
            {translation}
          </MenuItem>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};
