import { ThemeComponentType } from '../types';

export const Tabs: ThemeComponentType = {
  defaultProps: {
    variant: 'pills',
  },
  styles: theme => ({
    tab: {
      padding: theme.other.spacing(0.5, 1.5),
      borderWidth: 0,
      fontWeight: 500,
      border: `1px solid ${theme.colors[theme.primaryColor][9]}`,
      color: theme.colors[theme.primaryColor][9],
      opacity: 0.5,
      '&[data-active]': {
        color: '#ffffff',
        backgroundColor: theme.colors[theme.primaryColor][9],
        opacity: 1,
        '&:hover': {
          backgroundColor: theme.colors[theme.primaryColor][9],
        },
      },
      '&:hover': {
        opacity: 1,
        backgroundColor: 'transparent',
      },
      '&:disabled': {
        border: 0,
        opacity: 1,
        '&:hover': {
          color: theme.colors.gray[3],
          backgroundColor: theme.colors.gray[1],
        },
      },
    },
    tabLabel: {
      fontSize: theme.fontSizes.md,
      lineHeight: '24px',
    },
  }),
};
