const typesMap = {
  text: {
    type: 'string',
  },
  textarea: {
    type: 'string',
  },
  boolean: {
    type: 'boolean',
  },
  integer: {
    type: 'integer',
  },
  money: {
    type: 'number',
  },
  select: {
    type: 'string',
  },
  tabs: {
    type: 'string',
  },
  date: {
    type: 'string',
    format: 'date',
  },
  company: {
    $ref: '#/components/schemas/Contractor',
  },
};

export default typesMap;
