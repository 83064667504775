import getType from './getType';
import getDescription from './getDescription';
import getRange from './getRange';
import getEnums from './getEnums';

const getProperties = variables =>
  variables.reduce(
    (
      accumulator,
      { name, apiDescription, type, list, options, minValue, maxValue },
    ) => ({
      ...accumulator,
      [name]: {
        ...getDescription(apiDescription),
        ...getType(type, list),
        ...getRange(minValue, type, 'minimum'),
        ...getRange(maxValue, type, 'maximum'),
        ...getEnums(options),
      },
    }),
    {},
  );

export default getProperties;
