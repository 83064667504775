import { useQuery } from '@tanstack/react-query';

import getAuthConfig from '../requests/getAuthConfig';
import { API_QUERY_KEYS } from '../../../consts';

export const useGetAuthConfig = (options?: {
  onError?: (error: unknown) => void;
}) =>
  useQuery({
    queryKey: [API_QUERY_KEYS.authConfig],
    queryFn: getAuthConfig,
    ...options,
  });
