import { Ref, RefObject, useImperativeHandle } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Controller, useForm, UseFormSetError } from 'react-hook-form';
import { Anchor, Box, DefaultProps } from '@mantine/core';
import { STATIC_URLS } from 'consts';
import { PhoneInput, TextInput } from 'components/shared';
import { zodResolver } from '@hookform/resolvers/zod/dist/zod';

import { FormValuesType } from '../types';
import { useGetZodSchema } from './hooks';
import { useSsoEnabled } from '../../../../../../hooks';

type Props = {
  sx: DefaultProps['sx'];
  formRef: RefObject<HTMLFormElement>;
  formInstanceRef: Ref<{
    setFormError: UseFormSetError<FormValuesType>;
  }>;
  defaultValues: FormValuesType;
  onSubmit: (formValues: FormValuesType) => void;
};

export const PersonalInformationForm = ({
  sx,
  formRef,
  formInstanceRef,
  defaultValues,
  onSubmit,
}: Props) => {
  const { t } = useTranslation(['common', 'user']);
  const sso = useSsoEnabled();

  const schema = useGetZodSchema();

  const {
    control,
    formState: { errors: formErrors },
    handleSubmit,
    register,
    setError,
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues,
  });

  useImperativeHandle(formInstanceRef, () => ({
    setFormError: setError,
  }));

  return (
    <Box
      sx={sx}
      component="form"
      ref={formRef}
      onSubmit={handleSubmit(onSubmit)}
    >
      <TextInput
        styles={theme => ({
          description: {
            margin: theme.other.spacing(0.5, 0, 0),
          },
        })}
        {...register('email')}
        label={t('common:email')}
        placeholder={t('common:emailPlaceholder')}
        description={t('user:emailDescription')}
        error={formErrors.email?.message}
        inputWrapperOrder={['label', 'input', 'description', 'error']}
        disabled={sso}
      />
      <TextInput
        {...register('name')}
        label={t('common:name')}
        placeholder={t('common:name')}
        error={formErrors.name?.message}
      />
      <TextInput
        {...register('surname')}
        label={t('common:surname')}
        placeholder={t('common:surname')}
        error={formErrors.surname?.message}
      />
      <Controller
        name="phone"
        control={control}
        render={({ field: { ref, onChange, ...field } }) => (
          <PhoneInput
            {...field}
            onChange={(value, data, e) => {
              onChange(e);
            }}
            inputProps={ref}
            label={t('common:phoneNumber')}
            description={
              <Trans
                i18nKey="user:phoneDescription"
                components={{
                  a: (
                    <Anchor href={STATIC_URLS.privacyPolicy} target="_blank" />
                  ),
                }}
              />
            }
            error={formErrors.phone?.message}
          />
        )}
      />
    </Box>
  );
};
